import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { withStyles } from "@material-ui/core"
import { ContentContainer } from "../components/molecules/content-container"
import { ArticlesList } from "../components/organisms/articles-list"
import Typography from "@material-ui/core/Typography"


class BlogIndex extends React.Component {
  render() {
    const { data, classes } = this.props
    const { site, allMarkdownRemark, mainPagePrev } = data
    const siteTitle = site.siteMetadata.title
    const posts = allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="All posts"
          imagePath={mainPagePrev && mainPagePrev.publicURL}
        />

        <ContentContainer>
          <Typography
            className={classes.pageTitle}
            variant="h2"
          >
            Sharing projects and our experience
          </Typography>

          <ArticlesList
            posts={posts}
          />
        </ContentContainer>
      </Layout>
    )
  }
}

const styles = (theme) => {
  return {
    pageTitle: {
      fontSize: 54,
      lineHeight: "55px",
      fontWeight: 700,
      letterSpacing: "-1px",
      color: "#1A1A1A",
      maxWidth: 770,
      marginBottom: 100,

      [theme.breakpoints.down("xs")]: {
        fontSize: 30,
        lineHeight: "40px",
        marginBottom: 50,
      },
    },
    pageSubTitle: {
      fontSize: 24,
      lineHeight: "38px",
      letterSpacing: "-0.5px",
      fontWeight: 300,
      color: "#4D4D4D",

      [theme.breakpoints.down("xs")]: {
        fontSize: 20,
        lineHeight: "30px",
      },
    },
    description: {
      textTransform: "initial",
      marginTop: "30px",
      marginBottom: "40px",
    },
    readMoreLink: {
      position: "relative",
      zIndex: "99",
      fontFamily: "Europa",
      fontSize: "18px",
      fontWeight: "bold",
      color: "#ffab13",
      textDecoration: "underline",
      boxShadow: "none",
      "&:hover": {
        color: "#e69400",
      },
    },
    blogItem: {
      position: "relative",
      paddingBottom: "160px",
      "&:last-child": {
        paddingBottom: "0",
        "&:before": {
          content: "none",
        },
      },
      "&:before": {
        content: "''",
        borderBottom: "1px solid #e6e6e6",
        position: "absolute",
        bottom: "80px",
        width: "100%",
      },
    }
  }
}

export default withStyles(styles)(BlogIndex)

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            preview
          }
        }
      }
    }
    mainPagePrev: file(relativePath: { eq: "main_page_preview.png" }) {
      extension
      publicURL
    },
  }
`
